<template>
    <div>
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <div v-show="!importOngoing && !importDone">
            <b-row class="mt-2 mx-1">
                <b-col sm="12">
                    <!-- Asset Form -->
                    <b-card title="Upload Form">
                        <b-row class="mt-2 mb-4 ml-1" no-gutters>
                            <b-col sm="10" md="8" lg="6" class="mr-4">
                                <b-form-group label="Asset Form"
                                    description="Please select a valid json or csv file for asset import form format.">
                                    <b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
                                        :state="Boolean(file)" placeholder="Choose a JSON or CSV file" />
                                </b-form-group>
                                <div>
                                    Download JSON template
                                    <a :href="jsonTemplateUrl" download="AssetImporter.json">
                                        here
                                    </a>
                                    and CSV template
                                    <a :href="csvTemplateUrl" download="AssetImporter.csv">
                                        here.
                                    </a>
                                </div>
                            </b-col>
                            <b-col sm="1">
                                <b-button variant="primary" class="reset-button"
                                    @click="startAnotherImport()">Reset</b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <!-- Content Preview -->
                    <b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
                        sub-title="Below is the overview of the content of the asset import form you have selected">
                        <json-viewer :value="jsonData" class="my-4" />
                        <b-button variant="primary" @click="onImportData()">
                            Import
                        </b-button>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- Import Status -->
        <div v-show="importOngoing || importDone">
            <b-row class="my-4">
                <b-col sm="12">
                    <b-card title="Import Status" sub-title>
                        <div v-if="importErrors.length > 0">
                            <p class="card-text">{{ importResultLog }}</p>
                            <ul>
                                <li v-for="(item, index) in importErrors" :key="index">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>

                        <div v-else>
                            <p class="card-text my-4">
                                {{ importStatusDisplay }}
                            </p>
                        </div>

                        <span v-show="!importOngoing">
                            <b-button variant="primary" @click="startAnotherImport()">
                                Start Another Import
                            </b-button>
                        </span>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ImportUtil } from '@/utils/importUtil';

// API
import dataImporterApi from '@/api/dataImporterApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/env-constants';
let Papa = require('papaparse');
import _ from 'lodash';

export default {
    name: 'new-asset-importer',
    components: {
        Loading
    },
    data() {
        return {
            file: null,
            jsonData: null,

            importOngoing: false,
            importDone: false,
            hasError: false,
            importResultLog: '',
            importErrors: [],

            jsonTemplateUrl: '../../assets/files/AssetImporter.json',
            csvTemplateUrl: '../../assets/files/AssetImporter.csv',

            allAssetTypesObj: {},
            allAssetTypeCodesObj: {},
            allCompaniesObj: {},
            allStorageLocationsObj: {},

            currUserId: this.$store.getters.loggedUser.id,
            isLoading: false,
        }
    },
    computed: {
        importStatusDisplay() {
            let statusDisplay = '';

            if (this.importOngoing) {
                statusDisplay = 'Import In-Progress.';
            } else if (this.hasError) {
                statusDisplay = this.importResultLog;
            } else {
                statusDisplay = 'Import Successful! \n' + this.importResultLog;
            }
            return statusDisplay;
        },
        assetCodeRegex() {
            return config.assetCodeRegex;
        },
        serialNoRegex() {
            return config.serialNoRegex;
        },
        remarksRegex() {
            return config.remarksRegex;
        },
    },
    mounted() {
        setTimeout(() => {
            try {
                // show loading indicator
                this.isLoading = true;

                this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
                this.allAssetTypeCodesObj =  { ...this.$store.getters.assetTypeCodes }
                this.allCompaniesObj = { ...this.$store.getters.companies };
                this.allStorageLocationsObj = { ...this.$store.getters.storageLocations };

            } catch (_error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
				// hide loading indicator
				this.isLoading = false;
			}
        }, config.timeout);
    },
    methods: {
        startAnotherImport() {
            this.importDone = false;
            this.importOngoing = false;

            // reset error status
            this.resetErrorStatus();

            // reset form fields
            this.$refs.fileinput.reset();
            this.file = null;
            this.jsonData = null;
        },
        resetErrorStatus() {
            this.hasError = false;
            this.importResultLog = '';
            this.importErrors = [];
        },

        onUploadForm(ev) {
            const file = ev.target.files[0];

            if (this.validateUploadForm(file)) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let extension = file.name.split('.').pop().toLowerCase();
                    if (extension === 'csv') {
                        const { data } = Papa.parse(e.target.result, {
                            header: true,
                            skipEmptyLines: true,
                            dynamicTyping: true,
                            encoding: "UTF-8",
                        });

                        this.jsonData = this.formatJsonData(data);
                    } else {
                        this.jsonData = JSON.parse(e.target.result);
                        this.jsonData = this.formatJsonData(this.jsonData.assets);
                    }
                };

                reader.readAsText(file);
            }
        },
        validateUploadForm(file) {
            let isValid = true;
            const fileTypes = ['csv', 'json'];
            const extension = file.name.split('.').pop().toLowerCase();
            const isAllowed = fileTypes.indexOf(extension) > -1;

            if (!file) {
                this.$toaster.warning(
                    'Please select a valid Asset Import Form to proceed.'
                );
                isValid = false;
                this.jsonData = null;
            } else if (!isAllowed) {
                this.$toaster.error(
                    'Invalid File Type: Please import a valid Asset Import Form in JSON or CSV format.'
                );
                isValid = false;
                this.jsonData = null;
            }

            return isValid;
        },
        formatJsonData(params) {
            let results = [];
            params.forEach((param) => {
                param = ImportUtil.trimWhiteSpaces(param);

                if (param.purchaseDate) {
                    param.purchaseDateTimeStamp = DateUtil.startDateTimeStamp(param.purchaseDate);
                }
                if (param.expirationDate) {
                    param.expirationDateTimeStamp = DateUtil.startDateTimeStamp(param.expirationDate);
                }
                if (param.unitCost) {
                    param.unitCost = parseFloat(param.unitCost);
                }

                results.push(param);
            });

            return {
                assets: results,
            };
        },

        async onImportData() {
            let assetsArr = this.jsonData.assets;
            if (assetsArr.length === 0) {
                this.$toaster.warning('At least 1 asset is required per import.');
                return;
            } else if (assetsArr.length > 5000) {
                this.$toaster.warning(
                    'Only maximum of 5,000 assets is allowed per import.'
                );
                return;
            } else if (ImportUtil.hasDuplicates(assetsArr, 'assetCode')) {
                let duplicates = ImportUtil.getDuplicates(assetsArr, 'assetCode');
                this.$toaster.warning(
                    'Duplicates detected! Please address the following asset(s): ' +
                    JSON.stringify(duplicates)
                );
                return;
            } else if (ImportUtil.hasBlankColumnName(assetsArr[0])) {
                this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
                return;
            } else if (!this.validateDataImport(assetsArr)) {
                return;
            }

            await this.saveOnDatabase(this.jsonData);
        },
        validateDataImport(assets) {
            let isValid = true;
            let errors = [];

            let companies = Object.values({ ...this.allCompaniesObj }).map(company => company.name);
            let storageLocations = Object.values({ ...this.allStorageLocationsObj }).map(loc => loc.name);
            let allowedCompanyIds = _.map(this.allCompaniesObj, 'id');

            _.forEach(assets, (item, key) => {
                let assetCode = item.assetCode;
                let index = key + 1;
                let errPrefix = (!assetCode || assetCode.length === 0) ? 'Item ' + index : 'Asset ' + assetCode;

                // Asset Code
                if (!item.assetCode || item.assetCode.length === 0) {
                    errors.push(errPrefix + ' has no asset code. This is required.');
                }

                // Asset Details
                if (!item.assetName || item.assetName.length === 0) {
                    errors.push(errPrefix + ' has no name. This is required.');
                }

                // Serial No
                if (item.serialNo && item.serialNo.length > 0 && !this.serialNoRegex.test(item.serialNo)) {
                    errors.push(errPrefix + ' serial no. should only contain uppercase letters and numbers without white spaces.');
                }

                // Description
                if (item.description && item.description.length > 0 && !this.remarksRegex.test(item.description)) {
                    errors.push(errPrefix + ' description should only contain letters, numbers and these special characters: &\'"-,/():;!*[]');
                }

                // Purchase Date & Expiration Date
                let purchaseDate = item.purchaseDate;
                let expirationDate = item.expirationDate;
                
                if (purchaseDate) {
                    if (!ImportUtil.isValidDate(purchaseDate)) {
                        errors.push(errPrefix + ' purchase date, "' + purchaseDate + '", should follow the MM-DD-YYYY or MM/DD/YYYY format.');
                    } else if (!expirationDate || expirationDate.length === 0) {
                        errors.push(errPrefix + ' purchase date, "' + purchaseDate + '", should have its corresponding expiration date.');
                    } else {
                        let purchaseDateTimeStamp = DateUtil.getTimestamp(item.purchaseDate);
                        if (purchaseDateTimeStamp > DateUtil.getCurrentTimestamp()) {
                            errors.push(errPrefix + ' purchase date, ' + item.purchaseDate + ' is invalid. Future date is not allowed.');
                        }
                    }
                }

                if (expirationDate && !ImportUtil.isValidDate(expirationDate)) {
                    errors.push(errPrefix + ' expiration date, "' + expirationDate + '", should follow the MM-DD-YYYY or MM/DD/YYYY format.');
                } else if (expirationDate && (!purchaseDate || purchaseDate.length === 0)) {
                    errors.push(errPrefix + ' expiration date, "' + expirationDate + '", should have its corresponding purchase date.');
                }

                // Unit Cost
                if (item.unitCost && !ImportUtil.isValidNumber(item.unitCost)) {
                    errors.push(errPrefix + ' unit cost, "' + item.unitCost + '", should be a number.');
                }

                // Asset Type, Condition & Category
                if (!item.condition || item.condition.length === 0) {
                    errors.push(errPrefix + ' has no condition. This is required.');
                }
                if (!item.assetType || item.assetType.length === 0) {
                    errors.push(errPrefix + ' has no asset type. This is a required.');
                }

                let assetTypeObj = ImportUtil.getAssetType(this.allAssetTypesObj, item.assetType);
                if (item.assetType && item.assetType.length !== 0 && _.isEmpty(assetTypeObj)) {
                    errors.push(errPrefix + ' has a non-existent asset type, "' + item.assetType + '".');
                } else if (!_.isEmpty(assetTypeObj)) {
                    if (!allowedCompanyIds.includes(assetTypeObj.originId)) {
                        errors.push(errPrefix + ' has an asset type, "' + item.assetType + '", that you don\'t have access to.');
                    } else if (item.condition && !ImportUtil.isValidCondition(assetTypeObj, item.condition)) {
                        let errMsg = errPrefix + ' condition, "' + item.condition + '", is invalid. Valid conditions are: ';
                        errMsg += _.map(assetTypeObj.conditions, 'condition').join() + ".";
                        errors.push(errMsg);
                    } else if (item.category && assetTypeObj.hasCategories && !ImportUtil.isValidCategory(assetTypeObj, item.category)) {
                        let errMsg = errPrefix + ' category, "' + item.category + '", is invalid. Valid categories are: ';
                        errMsg += _.map(assetTypeObj.categories, 'name').join() + ".";
                        errors.push(errMsg);
                    }

                    if (assetTypeObj.assetTagging === 'Custom') {
                        let regex = new RegExp(assetTypeObj.regex);
                        if (!regex.test(assetCode)) {
                            errors.push(errPrefix + ' has an asset code that didn\'t follow the format of its asset type, "' + item.assetType + '": ' + assetTypeObj.regex);
                        }
                    } else {
                        let assetTypeCodesArr = _.filter(this.allAssetTypeCodesObj, o => {
                            return o.assetTypeId === assetTypeObj.id;
                        });

                        let regexStr = '';
                        let index = 0;
                        for(let assetTypeCode of assetTypeCodesArr) {
                            let prefix = assetTypeCode.prefix;
                            if (index !== 0) {
                                regexStr += "|"
                            }
                            regexStr += "^" + prefix + "[a-hj-km-np-z0-9]{6}$";  
                            index++; 
                        }

                        let regex = new RegExp(regexStr);
                        if (!regex.test(assetCode)) {
                            errors.push(errPrefix + ' has an asset code that didn\'t follow the format of its asset type, "' + item.assetType + '": ' + regexStr);
                        }
                    }                    
                }

                // Company and Storage Location
                if (!item.company || item.company.length === 0) {
                    errors.push(errPrefix + ' has no company. This is a required.');
                }
                if (item.company && item.company.length !== 0 && !companies.includes(item.company)) {
                    errors.push(errPrefix + ' is associated with an unknown company, "' + item.company + '".');
                }
                if (!item.storageLocation || item.storageLocation.length === 0) {
                    errors.push(errPrefix + ' has no storage location. This is a required.');
                }
                if (item.storageLocation && item.storageLocation.length !== 0 && !storageLocations.includes(item.storageLocation)) {
                    errors.push(errPrefix + ' is associated with an unknown storage location, "' + item.storageLocation + '".');
                }

            });

            if (errors.length > 0) {
                isValid = false;

                this.hasError = true;
                this.importErrors = errors;
                this.importResultLog = 'The imported form has error(s).';
                this.importDone = true;
                this.importOngoing = false;
            }

            return isValid;
        },
        async saveOnDatabase(json) {
            try {
                // show loading indicator
                this.isLoading = true;

                this.importOngoing = true;
                this.importDone = false;

                let param = {
                    currUserId: this.currUserId,
                    currTimeStamp: DateUtil.getCurrentTimestamp(),
                    assets: json.assets,
                    operation: config.assetHistoryOperation.IMPORT_ASSET,
                };

                let { data } = await dataImporterApi.importAssets(param);

                this.hasError = !data.isSuccess;
                this.importErrors = data.errors;
                this.importResultLog = data.message;
                this.importDone = true;
                this.importOngoing = false;

                // update store
                let assetsObj = data.assets;
                this.$store.dispatch('updateAllAssets', assetsObj);
            } catch (error) {
                this.hasError = true;
                this.importResultLog = error;
                this.importDone = true;
                this.importOngoing = false;
            } finally {
				// hide loading indicator
				this.isLoading = false;
			}
        },
    }
}
</script>