<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> Asset Importer</b-card-title>
			<b-card-sub-title>Imports asset data in bulk</b-card-sub-title>

			<b-form @submit.stop.prevent="handleSubmit" novalidate>
				<div class="mt-4">
					<b-tabs>
						<b-tab title="Import New Assets" active>
							<NewAssetImporter />
						</b-tab>
						<b-tab title="Update Asset Details">
							<ExistingAssetImporter />
						</b-tab>
					</b-tabs>
				</div>
			</b-form>
		</b-card>
	</div>
</template>

<script>
// Components
import NewAssetImporter from './assetImporter/NewAssetImporter.vue';
import ExistingAssetImporter from './assetImporter/ExistingAssetImporter.vue';

// Others
import config from '@/config/env-constants';

export default {
	name: 'asset-importer',
	components: {
		NewAssetImporter,
		ExistingAssetImporter
	},
	mounted() {
		setTimeout(() => {
			// Filter Access
			if (this.$store.getters.isViewer
				|| this.$store.getters.isScanner
				|| this.$store.getters.isAccounting
				|| this.$store.getters.isApprover
				|| this.$store.getters.isMaintenance) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}
		}, config.timeout);
	},
};
</script>